<template>
  <span
    v-if="from == currentAddr && from != to && from != tokenTo"
    class="
      u-label u-label--xs u-label--warning
      color-strong
      text-uppercase text-center
      rounded
      text-nowrap
    "
    style="width: 50px"
    >OUT</span
  >
  <span
    v-else-if="
      (to == currentAddr || tokenTo == currentAddr) &&
      from != to &&
      from != tokenTo
    "
    class="
      u-label u-label--xs u-label--success
      color-strong
      text-uppercase text-center
      rounded
      text-nowrap
    "
    style="width: 50px"
    >&nbsp;IN&nbsp;</span
  >
  <span
    v-else
    class="
      u-label u-label--xs u-label--secondary
      color-strong
      text-uppercase text-center
      rounded
      text-nowrap
    "
    style="width: 50px"
    >SELF</span
  >
</template>

<script>
export default {
  props: {
    currentAddr: "",
    from: "",
    to: "",
    tokenTo: "",
  },
};
</script>
