<template>
  <main id="content" role="main">
    <div class="container py-3">
      <div class="d-flex flex-wrap justify-content-between align-items-center">
        <div class="mb-3 mb-lg-0">
          <h1 class="h4 mb-0">
            Address
            <span
              id="mainaddress"
              class="text-size-address text-secondary text-break mr-1"
              data-placement="top"
              >{{ currentAddr }}
            </span>
            <img width="25" v-if="addressLogo" :src="addressLogo" alt="" />
          </h1>
          <div class="mt-1"></div>
        </div>
        <div class="d-flex flex-wrap"></div>
      </div>
    </div>
    <div id="overlayMain"></div>
    <div class="container"></div>
    <div id="ContentPlaceHolder1_divSummary" class="container space-bottom-2">
      <div class="row mb-4">
        <div class="col-md-6 mb-3 mb-md-0">
          <div class="card h-100">
            <div
              class="
                card-header
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <h2 class="card-header-title">Overview</h2>
              <div></div>
            </div>
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-md-4 mb-1 mb-md-0">Balance:</div>
                <div class="col-md-8">
                  {{ currentAddressBalance }} {{ getName("Common", "Unit") }}
                </div>
              </div>
              <hr class="hr-space" />
              <div class="row align-items-center">
                <div class="col-md-4 mb-1 mb-md-0">RCH Value:</div>
                <div class="col-md-8">${{ formatNumber(price, 6) }}</div>
              </div>
              <div id="token-balance" style="display: none">
                <hr class="hr-space" />
                <div class="row align-items-center">
                  <div class="col-md-4 mb-1 mb-md-0">Token:</div>
                  <div class="col-md-8">
                    <a
                      class="u-header__nav-link-toggle"
                      href="javascript:void(0)"
                      id="a-balance"
                    >
                      <span v-if="tokenBalance.length > 0">
                        {{ tokenBalance[0].value }}
                        {{ tokenBalance[0].symbol }}
                        {{
                          tokenBalance[0].memo
                            ? "[" + tokenBalance[0].memo + "]"
                            : ""
                        }}
                      </span>
                    </a>
                    <div
                      id="token_balance_a"
                      class="card"
                      style="
                        min-width: 230px;
                        display: none;
                        position: absolute;
                        z-index: 999;
                        left: -10px;
                        top: 30px;
                      "
                    >
                      <ul id="ul_balance">
                        <template v-for="item in tokenBalance" :key="item._id">
                          <li>
                            <a
                              href="#"
                              @click.prevent="showContract(item.address)"
                              class="nav-link u-header__sub-menu-nav-link"
                            >
                              {{ item.value }} {{ item.symbol }}
                              {{ item.memo ? "[" + item.memo + "]" : "" }}
                            </a>
                          </li>
                          <li class="sep"></li>
                        </template>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="ContentPlaceHolder1_cardright" class="col-md-6">
          <div class="card h-100">
            <div
              class="
                card-header
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <div id="ContentPlaceHolder1_td_misc" class="d-flex">
                <h2 class="card-header-title mr-1">More Info</h2>
              </div>
              <div id="ContentPlaceHolder1_moreoptionsdiv" class="d-flex">
                <div class="ml-auto mr-1"></div>
              </div>
            </div>
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-md-4 mb-1 mb-md-0">Memo:</div>
                <div class="col-md-8">
                  <span id="Public_Private_Tag">
                    {{ addressMemo }}
                  </span>
                  <span id="Public_Private_Tag_edit_button"></span>
                </div>
              </div>
              <hr class="hr-space" />
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div
          class="
            card-header
            d-flex
            justify-content-between
            align-items-center
            p-0
          "
        >
          <ul
            class="nav nav-custom nav-borderless nav_tabs1"
            id="nav_tabs"
            role="tablist"
          >
            <li id="ContentPlaceHolder1_li_transactions" class="nav-item">
              <a
                class="nav-link active"
                data-title="Primary Transactions"
                data-toggle="tab"
                href="#transactions"
                @click.prevent="changeTab(1)"
              >
                Transactions
              </a>
            </li>
            <li id="ContentPlaceHolder1_li_tokentransfers" class="nav-item">
              <a
                class="nav-link"
                href="#internal"
                @click.prevent="changeTab(5)"
                data-toggle="tab"
              >
                Internal Txns
              </a>
            </li>
            <li id="ContentPlaceHolder1_li_tokentransfers" class="nav-item">
              <a
                class="nav-link"
                href="#tokenTransactions"
                @click.prevent="changeTab(2)"
                data-toggle="tab"
              >
                Token Txs
              </a>
            </li>
            <li
              id="ContentPlaceHolder1_li_tokenInnertransfers"
              class="nav-item"
            >
              <a
                class="nav-link"
                href="#tokenTransactions"
                @click.prevent="changeTab(3)"
                data-toggle="tab"
              >
                RCH-20 Token Txs
              </a>
            </li>

            <li id="ContentPlaceHolder1_li_erc721Transfers" class="nav-item">
              <a
                class="nav-link"
                href="#erc721Transfers"
                @click.prevent="changeTab(4)"
                data-toggle="tab"
              >
                RCH-721 Token Txs
              </a>
            </li>
          </ul>
        </div>

        <!-- Transactions  -->
        <div class="card-body" id="card-body">
          <div class="tab-content">
            <div
              class="tab-pane fade show active"
              id="transactions"
              role="tabpanel"
              aria-labelledby="transactions-tab"
            >
              <!-- txns  -->
              <div
                class="table-responsive mb-2 mb-md-0"
                v-loading="txnsLoading"
              >
                <table class="table table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col">Txn Hash</th>
                      <th scope="col">Block</th>
                      <th scope="col">
                        <a
                          href="javascript:void(0)"
                          @click="txns.isAge = !txns.isAge"
                          >{{ txns.isAge ? "Age" : "Time" }}</a
                        >
                      </th>
                      <th scope="col">
                        <div class="d-flex align-items-center">
                          <div class="mr-2">From</div>
                        </div>
                      </th>
                      <th scope="col" width="30"></th>
                      <th scope="col">
                        <div class="d-flex align-items-center">
                          <div class="mr-2">To</div>
                        </div>
                      </th>
                      <th scope="col" width="1">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in txns.rows" :key="item._id">
                      <td>
                        <ItemHash :hash="item.hash" />
                      </td>
                      <td>
                        <a
                          href="#"
                          @click.prevent="showBlock(item.blockNumber)"
                          >{{ item.blockNumber }}</a
                        >
                      </td>
                      <td style="" class="showAge">
                        <TimeAge
                          :timestamp="item.timestamp"
                          :isAge="token_inner_txns.isAge"
                        />
                      </td>
                      <td>
                        <ItemAddress
                          :currentAddress="currentAddr"
                          :address="item.from"
                          :info="item.fromInfo"
                        />
                      </td>
                      <td>
                        <ItemTrxFrom
                          :currentAddr="currentAddr"
                          :from="item.from"
                          :to="item.to"
                        />
                      </td>
                      <td>
                        <ItemAddress
                          :currentAddress="currentAddr"
                          :address="item.to"
                          :info="item.toInfo"
                        />
                      </td>
                      <td>
                        {{ formatNumber(item.value, 4) }} {{ item.symbol }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <NoData :count="txns.rows.length" />
              </div>

              <Page
                :execPage="execPage"
                :total="txns.total"
                :currentPage="txns.currentPage"
                :pageSize="pageSize"
              />
            </div>
          </div>
        </div>

        <!--  Token Transactions  -->
        <div class="card-body" id="card-body1" style="display: none">
          <div class="tab-content">
            <div
              class="tab-pane fade"
              id="tokenTransactions"
              role="tabpanel"
              aria-labelledby="transactions-tab"
            >
              <div class="d-md-flex align-items-center mb-3">
                <div
                  class="
                    d-flex
                    flex-wrap flex-xl-nowrap
                    text-nowrap
                    align-items-center
                    ml-auto
                  "
                ></div>
                <div class="position-relative ml-1"></div>
              </div>
              <div class="table-responsive mb-2 mb-md-0">
                <div
                  class="table-responsive mb-2 mb-md-0"
                  v-loading="tokenLoading"
                >
                  <table class="table table-hover">
                    <thead class="thead-light">
                      <tr>
                        <th scope="col">Txn Hash</th>
                        <th scope="col">Block</th>
                        <th scope="col">
                          <a
                            href="javascript:void(0)"
                            @click="token_txns.isAge = !token_txns.isAge"
                            >{{ token_txns.isAge ? "Age" : "Time" }}</a
                          >
                        </th>
                        <th scope="col">
                          <div class="d-flex align-items-center">
                            <div class="mr-2">From</div>
                          </div>
                        </th>
                        <th scope="col" width="30"></th>
                        <th scope="col">
                          <div class="d-flex align-items-center">
                            <div class="mr-2">To</div>
                          </div>
                        </th>
                        <th scope="col">Value</th>
                        <th scope="col">Token</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in token_txns.data" :key="item._id">
                        <td>
                          <ItemHash :hash="item.hash" />
                        </td>
                        <td>
                          <a
                            href="#"
                            @click.prevent="showBlock(item.blockNumber)"
                            >{{ item.blockNumber }}</a
                          >
                        </td>
                        <td style="" class="showAge">
                          <TimeAge
                            :timestamp="item.timestamp"
                            :isAge="token_txns.isAge"
                          />
                        </td>
                        <td>
                          <ItemAddress
                            :currentAddress="currentAddr"
                            :address="item.from"
                            :info="item.fromInfo"
                          />
                        </td>
                        <td>
                          <ItemTrxFrom
                            :currentAddr="currentAddr"
                            :from="item.from"
                            :to="item.to"
                          />
                        </td>
                        <td>
                          <ItemAddress
                            :currentAddress="currentAddr"
                            :address="item.to"
                            :info="item.toInfo"
                          />
                        </td>
                        <td>
                          {{ formatNumber(item.value, 4) }}
                        </td>
                        <td>
                          <ItemToken
                            :logo="item.token.logo"
                            :address="item.token.address"
                            :tokenName="item.token.tokenName"
                            :symbol="item.token.symbol"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <NoData :count="token_txns.data.length" />
                </div>
              </div>
              <Page
                :execPage="execPageToken"
                :total="token_txns.total"
                :currentPage="token_txns.currentPage"
                :pageSize="pageSize"
              />
            </div>
          </div>
        </div>

        <!--  Token Inner Transactions  -->
        <div class="card-body" id="card-body2" style="display: none">
          <div class="tab-content">
            <div
              class="tab-pane fade"
              id="tokenInnerTransactions"
              role="tabpanel"
              aria-labelledby="transactions-tab"
            >
              <div class="d-md-flex align-items-center mb-3">
                <div
                  class="
                    d-flex
                    flex-wrap flex-xl-nowrap
                    text-nowrap
                    align-items-center
                    ml-auto
                  "
                ></div>
                <div class="position-relative ml-1"></div>
              </div>
              <div class="table-responsive mb-2 mb-md-0">
                <div
                  class="table-responsive mb-2 mb-md-0"
                  v-loading="token_inner_txns.loading"
                >
                  <table class="table table-hover">
                    <thead class="thead-light">
                      <tr>
                        <th scope="col">Txn Hash</th>
                        <th scope="col">
                          <a
                            href="javascript:void(0)"
                            @click="
                              token_inner_txns.isAge = !token_inner_txns.isAge
                            "
                            >{{ token_inner_txns.isAge ? "Age" : "Time" }}</a
                          >
                        </th>
                        <th scope="col">
                          <div class="d-flex align-items-center">
                            <div class="mr-2">From</div>
                          </div>
                        </th>
                        <th scope="col" width="30"></th>
                        <th scope="col">
                          <div class="d-flex align-items-center">
                            <div class="mr-2">To</div>
                          </div>
                        </th>
                        <th scope="col">Value</th>
                        <th scope="col">Token</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in token_inner_txns.data" :key="item._id">
                        <td>
                          <ItemHash :hash="item.hash" />
                        </td>

                        <td style="" class="showAge">
                          <TimeAge
                            :timestamp="item.timestamp"
                            :isAge="token_inner_txns.isAge"
                          />
                        </td>
                        <td>
                          <ItemAddress
                            :currentAddress="currentAddr"
                            :address="item.from"
                            :info="item.fromInfo"
                          />
                        </td>
                        <td>
                          <ItemTrxFrom
                            :currentAddr="currentAddr"
                            :from="item.from"
                            :to="item.to"
                          />
                        </td>
                        <td>
                          <ItemAddress
                            :currentAddress="currentAddr"
                            :address="item.to"
                            :info="item.toInfo"
                          />
                        </td>
                        <td>
                          {{ formatNumber(item.value, 4) }}
                        </td>
                        <td>
                          <ItemToken
                            :logo="item.token.logo"
                            :address="item.token.address"
                            :tokenName="item.token.tokenName"
                            :symbol="item.token.symbol"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <NoData :count="token_inner_txns.data.length" />
                </div>
              </div>

              <Page
                :execPage="execPageTokenInner"
                :total="token_inner_txns.total"
                :currentPage="token_inner_txns.currentPage"
                :pageSize="pageSize"
              />
            </div>
          </div>
        </div>

        <div class="card-body" id="card-body721" style="display: none">
          <div class="tab-content">
            <div
              class="tab-pane fade"
              id="erc721Transfers"
              role="tabpanel"
              aria-labelledby="transactions-tab"
            >
              <div class="d-md-flex align-items-center mb-3">
                <div
                  class="
                    d-flex
                    flex-wrap flex-xl-nowrap
                    text-nowrap
                    align-items-center
                    ml-auto
                  "
                ></div>
                <div class="position-relative ml-1"></div>
              </div>
              <div class="table-responsive mb-2 mb-md-0">
                <div
                  class="table-responsive mb-2 mb-md-0"
                  v-loading="erc721Transfers.loading"
                >
                  <table class="table table-hover">
                    <thead class="thead-light">
                      <tr>
                        <th scope="col">Txn Hash</th>
                        <th scope="col">
                          <a
                            href="javascript:void(0)"
                            @click="
                              erc721Transfers.isAge = !erc721Transfers.isAge
                            "
                            >{{ erc721Transfers.isAge ? "Age" : "Time" }}</a
                          >
                        </th>
                        <th scope="col">
                          <div class="d-flex align-items-center">
                            <div class="mr-2">From</div>
                          </div>
                        </th>
                        <th scope="col" width="30"></th>
                        <th scope="col">
                          <div class="d-flex align-items-center">
                            <div class="mr-2">To</div>
                          </div>
                        </th>
                        <th scope="col">Token Id</th>
                        <th scope="col">Token</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in erc721Transfers.data" :key="item._id">
                        <td>
                          <ItemHash :hash="item.hash" />
                        </td>

                        <td style="" class="showAge">
                          <TimeAge
                            :timestamp="item.timestamp"
                            :isAge="erc721Transfers.isAge"
                          />
                        </td>
                        <td>
                          <ItemAddress
                            :currentAddress="currentAddr"
                            :address="item.from"
                            :info="item.fromInfo"
                          />
                        </td>
                        <td>
                          <ItemTrxFrom
                            :currentAddr="currentAddr"
                            :from="item.from"
                            :to="item.to"
                          />
                        </td>
                        <td>
                          <ItemAddress
                            :currentAddress="currentAddr"
                            :address="item.to"
                            :info="item.toInfo"
                          />
                        </td>
                        <td>
                          {{ item.value }}
                        </td>
                        <td>
                          <ItemToken
                            :logo="item.token.logo"
                            :address="item.token.address"
                            :tokenName="item.token.tokenName"
                            :symbol="item.token.symbol"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <NoData :count="erc721Transfers.data.length" />
                </div>
              </div>

              <Page
                :execPage="execPageErc721Transfers"
                :total="erc721Transfers.total"
                :currentPage="erc721Transfers.currentPage"
                :pageSize="pageSize"
              />
            </div>
          </div>
        </div>

        <!-- Internal Txns -->
        <div class="card-body" id="card-innernal" style="display: none">
          <div class="tab-content">
            <input
              type="hidden"
              id="hdnErc20"
              value=""
              onchange="setGlobalValue(this)"
            />
            <div
              class="tab-pane fade show active"
              id="transactions"
              role="tabpanel"
              aria-labelledby="transactions-tab"
            >
              <div class="d-md-flex align-items-center mb-3">
                <div
                  class="
                    d-flex
                    flex-wrap flex-xl-nowrap
                    text-nowrap
                    align-items-center
                    ml-auto
                  "
                ></div>
                <div class="position-relative ml-1"></div>
              </div>
              <div
                class="table-responsive mb-2 mb-md-0"
                v-loading="internalTxns.loading"
              >
                <table class="table table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col">Txn Hash</th>
                      <th scope="col">Block</th>
                      <th scope="col">
                        <a
                          href="javascript:void(0)"
                          @click="internalTxns.isAge = !internalTxns.isAge"
                          >{{ internalTxns.isAge ? "Age" : "Time" }}</a
                        >
                      </th>
                      <th scope="col">Type</th>
                      <th scope="col">From</th>
                      <th scope="col" width="30"></th>
                      <th scope="col">To</th>
                      <th scope="col">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in internalTxns.data" :key="item._id">
                      <td>
                        <i
                          class="fa fa-check-circle"
                          style="margin-end: 0.3rem"
                          :class="{
                            'text-success': item.status,
                            'text-danger': !item.status,
                          }"
                        ></i>
                        <ItemHash :hash="item.hash" />
                      </td>
                      <td>
                        <a
                          href="#"
                          v-on:click.prevent="showBlock(item.blockNumber)"
                          >{{ item.blockNumber }}</a
                        >
                      </td>
                      <td style="" class="showAge">
                        <TimeAge
                          :timestamp="item.timestamp"
                          :isAge="internalTxns.isAge"
                        />
                      </td>
                      <td>
                        {{ item.tracer }}
                      </td>
                      <td>
                        <ItemAddress
                          :currentAddress="currentAddr"
                          :address="item.from"
                          :info="item.fromInfo"
                        />
                      </td>
                      <td>
                        <ItemTrxFrom
                          :currentAddr="currentAddr"
                          :from="item.from"
                          :to="item.to"
                        />
                      </td>
                      <td>
                        <ItemAddress
                          :currentAddress="currentAddr"
                          :address="item.to"
                          :info="item.toInfo"
                        />
                      </td>
                      <td>
                        {{ formatNumber(item.value, 4) + " RCH" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <NoData :count="internalTxns.data.length" />
              </div>
              <Page
                :execPage="execInternalTxsPage"
                :total="internalTxns.count"
                :currentPage="internalTxns.page"
                :pageSize="internalTxns.pageNum"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <div id="push"></div>
</template>

<script>
import { getName } from "../../public/assets/js/lang.js";
import {
  PostNet,
  PostNetUrl,
  PostEthNetUrl,
} from "../../public/assets/js/myRequest.js";
import { getUrlKey } from "../../src/assets/js/utils.js";
import { formatNumber } from "../../public/assets/js/utils.js";
import { parseLongText } from "../../src/assets/js/utils.js";
import route from "../router/index";
import { ElMessage } from "element-plus";
import ItemAddress from "./components/itemAddress.vue";
import ItemTrxFrom from "./components/item_trx_from.vue";
import TimeAge from "./components/timeAge.vue";
import Page from "./components/page.vue";
import ItemToken from "./components/item_token.vue";
import ItemHash from "./components/item_hash.vue";
import NoData from "./components/noData.vue";
export default {
  name: "Address",
  inject: ["reload"], //注入依赖
  data() {
    return {
      txns: { total: 0, currentPage: 1, isAge: true, rows: [] },
      pageSize: 20,
      currentAddr: "",
      tokenList: {},
      currentAddressBalance: "",
      price: "0.1",
      addressLogo: "",
      addressMemo: "",
      token_txns: { total: 0, currentPage: 1, isAge: true, data: [] },
      tokenBalance: [],
      hasToken: false,
      txnsLoading: false,
      tokenLoading: false,
      token_inner_txns: {
        total: 0,
        isAge: true,
        currentPage: 1,
        data: [],
        loading: false,
      },
      token_inner_txns: {
        total: 0,
        isAge: true,
        currentPage: 1,
        data: [],
        loading: false,
      },
      erc721Transfers: {
        total: 0,
        isAge: true,
        currentPage: 1,
        data: [],
        loading: false,
      },
      internalTxns: {
        loading: false,
        count: 0,
        page: 1,
        pageNum: 20,
        data: [],
        isAge: true,
      },
    };
  },
  components: {
    ItemAddress,
    ItemTrxFrom,
    TimeAge,
    Page,
    ItemToken,
    ItemHash,
    NoData,
  },
  methods: {
    parseLongText(str, start = 10, end = 6) {
      return parseLongText(str, start, end);
    },
    getName(str1, str2) {
      return getName(str1, str2);
    },
    formatDate(time, fmt) {
      time = time * 1000;
      let date = new Date(time);
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      let o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : this.padLeftZero(str)
          );
        }
      }
      return fmt;
    },
    formateAge(timestamp) {
      if (!isNaN(timestamp)) {
        const myDate = new Date();
        const now = myDate.getTime();
        const dis = now - timestamp * 1000;
        var time = "--:--";
        var t = 0;
        if (dis < 0) {
          time = 0 + " " + getName("Home", "sec");
        } else if (dis < 60000) {
          t = parseInt(dis / 1000);
          time = t + " " + getName("Home", t > 1 ? "secs" : "sec");
        } else if (dis < 60 * 1000 * 60) {
          t = parseInt(dis / 1000 / 60);
          time = t + " " + getName("Home", t > 1 ? "mins" : "min");
        } else if (dis < 24 * 60 * 1000 * 60) {
          t = parseInt(dis / 1000 / 60 / 60);
          time = t + " " + getName("Home", t > 1 ? "hours" : "hour");
        } else if (dis < 365 * 24 * 60 * 1000 * 60) {
          t = parseInt(dis / 1000 / 60 / 60 / 24);
          time = t + " " + getName("Home", t > 1 ? "days" : "day");
        } else {
          t = parseInt(dis / 1000 / 60 / 60 / 24 / 365);
          time = t + " " + getName("Home", t > 1 ? "years" : "year");
        }
        time = time + " " + getName("Home", "ago");
      }
      return time;
    },
    padLeftZero(str) {
      return ("00" + str).substr(str.length);
    },
    formatNumber(number, decimail) {
      return formatNumber(number, decimail);
    },

    changeTab(index) {
      $("#card-body1").attr("style", "display:none");
      $("#card-body").attr("style", "display:none");
      $("#card-body2").attr("style", "display:none");
      $("#transactions").removeClass("show active");
      $("#tokenTransactions").removeClass("show active");
      $("#tokenInnerTransactions").removeClass("show active");
      $("#erc721Transfers").removeClass("show active");
      $("#card-body721").attr("style", "display:none");

      $("#card-innernal").attr("style", "display:none");
      switch (index) {
        case 1:
          $("#card-body").attr("style", "display:block");
          $("#transactions").addClass("show active");
          this.execPage(-2);
          break;
        case 2:
          $("#card-body1").attr("style", "display:block");
          $("#tokenTransactions").addClass("show active");
          this.execPageToken(-2);
          break;
        case 3:
          $("#card-body2").attr("style", "display:block");
          $("#tokenInnerTransactions").addClass("show active");
          this.execPageTokenInner(-2);
          break;
        case 4:
          $("#card-body721").attr("style", "display:block");
          $("#erc721Transfers").addClass("show active");
          this.execPageErc721Transfers(-2);
          break;
        case 5:
          $("#card-innernal").attr("style", "display:block");
          this.execInternalTxsPage(-2);
          break;
      }
    },
    execPage: function (page) {
      if (page === -2) {
        this.txns.currentPage = 1;
      } else {
        this.txns.currentPage =
          parseInt(this.txns.currentPage) + parseInt(page);
        if (isNaN(this.txns.currentPage)) {
          this.txns.currentPage = 1;
        }
        if (this.txns.currentPage <= 0) {
          this.txns.currentPage = 1;
          return;
        }
        let totalPage = parseInt(this.txns.total / this.pageSize) + 1;
        if (this.txns.currentPage > totalPage) {
          this.txns.currentPage = totalPage;
          return;
        }
      }
      this.txnsLoading = true;
      PostNet.PostWith(
        PostNetUrl.addressTransactionList,
        (info) => {
          this.txnsLoading = false;
          if (info.data) {
            this.txns.rows = info.data.data;
            this.txns.total = info.data.count;
          }
        },
        (err) => {
          this.txnsLoading = false;
        },
        {
          addr: this.currentAddr,
          page: this.txns.currentPage,
          PageNum: this.pageSize,
        }
      );
    },
    execPageToken: function (page) {
      if (page === -2) {
        this.token_txns.currentPage = 1;
      } else {
        this.token_txns.currentPage =
          parseInt(this.token_txns.currentPage) + parseInt(page);
        if (isNaN(this.token_txns.currentPage)) {
          this.token_txns.currentPage = 1;
        }
        if (this.token_txns.currentPage <= 0) {
          this.token_txns.currentPage = 1;
          return;
        }
        let totalPage = parseInt(this.token_txns.total / this.pageSize) + 1;
        if (this.token_txns.currentPage > totalPage) {
          this.token_txns.currentPage = totalPage;
          return;
        }
      }
      this.tokenLoading = true;
      PostNet.PostWith(
        PostNetUrl.getTokenTransactionListByAddrWithCount,
        (info) => {
          this.tokenLoading = false;
          if (info.data) {
            this.token_txns.data = info.data.data;
            this.token_txns.total = info.data.count;
          }
        },
        (err) => {
          this.tokenLoading = false;
        },
        {
          addr: this.currentAddr,
          page: this.token_txns.currentPage,
          PageNum: this.pageSize,
        }
      );
    },
    execPageTokenInner: function (page) {
      if (page === -2) {
        this.token_inner_txns.currentPage = 1;
      } else {
        this.token_inner_txns.currentPage =
          parseInt(this.token_inner_txns.currentPage) + parseInt(page);
        if (isNaN(this.token_inner_txns.currentPage)) {
          this.token_inner_txns.currentPage = 1;
        }
        if (this.token_inner_txns.currentPage <= 0) {
          this.token_inner_txns.currentPage = 1;
          return;
        }
        let totalPage =
          parseInt(this.token_inner_txns.total / this.pageSize) + 1;
        if (this.token_inner_txns.currentPage > totalPage) {
          this.token_inner_txns.currentPage = totalPage;
          return;
        }
      }
      this.token_inner_txns.loading = true;
      PostNet.PostWith(
        PostNetUrl.erc20TokenInnerTrxsByAddress,
        (info) => {
          this.token_inner_txns.loading = false;
          if (info.data) {
            this.token_inner_txns.data = info.data.data;
            this.token_inner_txns.total = info.data.count;
          }
        },
        (err) => {
          this.token_inner_txns.loading = false;
          ElMessage.error(err);
        },
        {
          address: this.currentAddr,
          page: this.token_inner_txns.currentPage,
          PageNum: this.pageSize,
        }
      );
    },
    execPageErc721Transfers: function (page) {
      if (page === -2) {
        this.erc721Transfers.currentPage = 1;
      } else {
        this.erc721Transfers.currentPage =
          parseInt(this.erc721Transfers.currentPage) + parseInt(page);
        if (isNaN(this.erc721Transfers.currentPage)) {
          this.erc721Transfers.currentPage = 1;
        }
        if (this.erc721Transfers.currentPage <= 0) {
          this.erc721Transfers.currentPage = 1;
          return;
        }
        let totalPage =
          parseInt(this.erc721Transfers.total / this.pageSize) + 1;
        if (this.erc721Transfers.currentPage > totalPage) {
          this.erc721Transfers.currentPage = totalPage;
          return;
        }
      }
      this.erc721Transfers.loading = true;
      PostNet.PostWith(
        PostNetUrl.erc721TokenTransferByAddress,
        (info) => {
          this.erc721Transfers.loading = false;
          if (info.data) {
            this.erc721Transfers.data = info.data.data;
            this.erc721Transfers.total = info.data.count;
          }
        },
        (err) => {
          this.erc721Transfers.loading = false;
          ElMessage.error(err);
        },
        {
          address: this.currentAddr,
          page: this.erc721Transfers.currentPage,
          PageNum: this.pageSize,
        }
      );
    },
    execInternalTxsPage: function (page) {
      if (page === -2) {
        this.internalTxns.page = 1;
      } else {
        this.internalTxns.page =
          parseInt(this.internalTxns.page) + parseInt(page);
        if (isNaN(this.internalTxns.page)) {
          this.internalTxns.page = 1;
        }
        if (this.internalTxns.page <= 0) {
          this.internalTxns.page = 1;
          return;
        }
        let totalPage =
          parseInt(this.internalTxns.count / this.internalTxns.pageNum) + 1;
        if (this.internalTxns.page > totalPage) {
          this.internalTxns.page = totalPage;
          return;
        }
      }

      this.internalTxns.loading = true;
      PostNet.PostWith(
        PostNetUrl.coinInners,
        (info) => {
          this.internalTxns.loading = false;
          this.internalTxns.data = info.data.data;
          this.internalTxns.count = info.data.count;
        },
        (err) => {
          this.internalTxns.loading = false;
        },
        {
          address: this.currentAddr,
          page: this.internalTxns.page,
          pagesize: this.internalTxns.pageNum,
        }
      );
    },

    showBlock(blockNum) {
      // location.href = "/block?b=" + blockNum;
      route.push({
        path: "/block",
        query: {
          b: blockNum,
        },
      });
    },
    showTransaction(item) {
      if (item.transferType) {
        if ("token" === item.transferType) {
          // location.href =
          //   "/tx/tokentx.html?h=" + item.hash + "&s=" + item.symbol;
          route.push({
            path: "/txTokenDetail",
            query: {
              h: item.hash,
            },
          });
          return;
        }
      }
      // location.href = "/tx/tx.html?h=" + item.hash;
      route.push({
        path: "/txDetail",
        query: {
          h: item.hash,
        },
      });
    },
    showContract(contract) {
      // location.href = "/token/index.html?c=" + contract;
      route.push({
        path: "/TokenDetail",
        query: {
          c: contract,
        },
      });
    },
    showAddressTransaction: function (addr) {
      // location.href = "/address?a=" + addr;
      route.push({
        path: "/address",
        query: {
          a: addr,
        },
      });
      // this.reload();
    },
  },
  mounted() {
    this.currentAddr = getUrlKey("a");
    this.txns.currentPage = parseInt(getUrlKey("p"));
    if (this.txns.currentPage <= 0 || isNaN(this.txns.currentPage)) {
      this.txns.currentPage = 1;
    }
    this.txnsLoading = true;
    PostNet.PostWith(
      PostNetUrl.addressTransactionList,
      (info) => {
        this.txnsLoading = false;
        if (info.data) {
          this.txns.rows = info.data.data;
          this.txns.total = info.data.count;
        }
      },
      (err) => {
        this.txnsLoading = false;
      },
      {
        addr: this.currentAddr,
        page: this.txns.currentPage,
        PageNum: this.pageSize,
      }
    );
    PostNet.Post2(
      PostEthNetUrl.getBalance,
      (info) => {
        if (info.code != 20000) {
          return;
        }
        if (parseFloat(info.data.balance) >= 0) {
          this.currentAddressBalance = info.data.balance;
          this.price = info.data.price;
          this.addressLogo = info.data.logo;
          this.addressMemo = info.data.memo;
        }
      },
      { addr: this.currentAddr }
    );
    PostNet.Post2(
      PostEthNetUrl.getTokenBalance,
      (info) => {
        this.tokenBalance = info.data;
        this.hasToken = this.tokenBalance.length > 0;
        if (this.hasToken) {
          $("#token-balance").attr("style", "display:block");
        } else {
          $("#token-balance").attr("style", "display:none");
        }
      },
      { addr: this.currentAddr }
    );

    (function () {
      var a = document.getElementById("a-balance");
      var list = document.getElementById("token_balance_a"); // 显示隐藏
      var timer = null;
      list.onmouseover = a.onmouseover = function () {
        if (timer) clearTimeout(timer);
        list.style.display = "block";
      };
      list.onmouseout = a.onmouseout = function () {
        timer = setTimeout(function () {
          list.style.display = "none";
        }, 400);
      };
    })();
  },
};
</script>
<style lang="less" scoped>
#ul_balance li {
  list-style: none;
}

#ul_balance {
  margin: 0;
  padding: 0.5rem 0;
}

#a-balance {
  color: #1e2022;
}

#a-balance:hover {
  color: #3498db;
}

#ul_balance li a {
  color: #1e2022;
}

#ul_balance li a:hover {
  color: #3498db;
}
.sep {
  list-style: none;
  height: 1px;
  background: #e7eaf3;
  overflow: hidden;
  line-height: 1px;
  margin: 0 0.5rem;
  padding: 0;
  font-size: 0px;
}
</style>
